import { Component } from '@angular/core';

@Component({
  selector: 'agd-offline-page',
  templateUrl: './offline-page.component.html',
  styleUrls: ['./offline-page.component.scss']
  })
export class OfflinePageComponent {
  reload() {
    window.location.reload();
  }
}
