import { inject } from '@angular/core';
import { CanActivateFn, CanDeactivateFn, Router } from '@angular/router';
import { OfflinePageComponent } from '../pages/offline-page/offline-page.component';
import { OfflineService } from '../services/offline.service';

export const OfflineGuardActivate: CanActivateFn = () => {
  const offlineService = inject(OfflineService);
  const router = inject(Router);

  const { isOffline } = offlineService;

  if (!isOffline) {
    return router.createUrlTree(['/']);
  }

  return isOffline;
};

export const OfflineGuardDeactivate: CanDeactivateFn<OfflinePageComponent> = () => !inject(OfflineService).isOffline;
