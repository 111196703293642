import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfflineRoutingModule } from './offline-routing.module';
import { OfflinePageComponent } from './pages/offline-page/offline-page.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
  OfflinePageComponent
  ],
  imports: [
  CommonModule,
  OfflineRoutingModule,
  MatButtonModule,
  ]
  })
export class OfflineModule {}
